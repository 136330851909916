import { Button, Popover } from 'antd';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';

import mobileIcon from '../assets/mobile.png';
import pcIcon from '../assets/pc.png';
import { store } from '../utils';

const Style = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    .ant-btn {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        border: none;
    }
`;

const DownContentStyle = styled.div`
    .title {
        font-size: 14px;
        color: #1a1a1a;
        font-weight: 700;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
    }
    .division-line {
        height: 1px;
        width: 100%;
        background: #e6e6e6;
        margin-bottom: 20px;
    }
    .pc {
        padding-bottom: 20px;
        .pc-content {
            .ant-btn {
                width: 180px;
                border-radius: 10px;
                background: linear-gradient(-90deg, #0ab2c1 0%, #27cedd 100%);
                box-shadow: 0 4px 7px 0 rgba(10, 178, 193, 0.4);
            }
        }
    }
    .mobile-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            font-size: 12px;
            color: #1a1a1a;
            margin-top: 8px;
        }
    }
`;

export default function () {
    const conf = store.get('sysConf');
    const { apkDownloadUrl, winDownloadUrl, iosDownloadUrl } = conf;

    // winDownloadUrl = 'https://dldir1.qq.com/qqfile/qq/PCQQ9.7.1/QQ9.7.1.28940.exe';
    // apkDownloadUrl = 'http://trauma.guanfangyiliao.com/conf/ReleaseApp.apk';
    const qrcontent =
        'http://test.guanfangyiliao.com' +
        '/trauma-apk-down/apk.html?apk=' +
        apkDownloadUrl;
    const iosQrcontent =
        'http://test.guanfangyiliao.com' +
        '/trauma-apk-down/apk.html?apk=' +
        iosDownloadUrl;
    const content = (
        <DownContentStyle>
            {winDownloadUrl ? (
                <div className='pc'>
                    <div className='title'>
                        <img src={pcIcon} alt='下载电脑客户端' />
                        电脑端
                    </div>
                    <div className='pc-content'>
                        <Button
                            type='primary'
                            onClick={() => window.open(winDownloadUrl)}
                        >
                            立即下载
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
            {apkDownloadUrl && winDownloadUrl ? (
                <div className='division-line'></div>
            ) : (
                ''
            )}
            {apkDownloadUrl ? (
                <div className='mobile'>
                    <div className='title'>
                        <img src={mobileIcon} alt='下载安卓端apk' />
                        安卓端
                    </div>
                    <div className='mobile-content'>
                        <QRCode
                            value={qrcontent}
                            imageSettings={{
                                src: '/favicon.ico',
                                height: 27,
                                width: 27,
                                excavate: true,
                            }}
                        />
                        <div>扫一扫 下载APP</div>
                    </div>
                </div>
            ) : (
                ''
            )}
            {iosDownloadUrl && (winDownloadUrl || apkDownloadUrl) ? (
                <div
                    className='division-line'
                    style={{ marginTop: '10px' }}
                ></div>
            ) : (
                ''
            )}
            {iosDownloadUrl ? (
                <div className='mobile'>
                    <div className='title'>
                        <img src={mobileIcon} alt='下载苹果端apk' />
                        苹果端
                    </div>
                    <div className='mobile-content'>
                        <QRCode
                            value={iosQrcontent}
                            imageSettings={{
                                src: '/favicon.ico',
                                height: 27,
                                width: 27,
                                excavate: true,
                            }}
                        />
                        <div>扫一扫 下载APP</div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </DownContentStyle>
    );

    return (
        <Style>
            <Popover
                content={content}
                placement='bottomLeft'
                trigger='click'
                overlayInnerStyle={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)',
                }}
            >
                {apkDownloadUrl || winDownloadUrl ? (
                    <Button type='primary' icon={<DownloadOutlined />}>
                        下载客户端
                    </Button>
                ) : (
                    ''
                )}
            </Popover>
        </Style>
    );
}
