import AES from 'crypto-js/aes';
import ECB from 'crypto-js/mode-ecb';
import encHex from 'crypto-js/enc-hex';
import Pkcs7 from 'crypto-js/pad-pkcs7';
import encUtf8 from 'crypto-js/enc-utf8';

export default class PasswordDispose {
    static secretKey = '13a586227b2a9a3d';

    // 加密
    public static encryptionPassword(password: string) {
        const encrypted = AES.encrypt(password, encUtf8.parse(this.secretKey), {
            mode: ECB,
            padding: Pkcs7,
        });
        return encHex.stringify(encrypted.ciphertext);
    }
    // 解密
    public static decodePassword(encryptedPassword: string) {
        // 首先，将十六进制字符串转换为WordArray
        const ciphertext: any = encHex.parse(encryptedPassword);

        // 使用相同的密钥、模式和填充方式进行解密
        const decrypted = AES.decrypt(
            { ciphertext } as any,
            encUtf8.parse(this.secretKey),
            {
                mode: ECB,
                padding: Pkcs7,
            }
        );
        // 返回解密后的UTF-8字符串
        return decrypted.toString(encUtf8);
    }
}
