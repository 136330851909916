import styled from 'styled-components';

import loginBack from './assets/login-back.png';
import jigou from './assets/jigou.png';

const LoginPage = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${loginBack});
    .login-box {
        padding: 55px;
        width: 1167px;
        height: 650px;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgba(10, 178, 193, 0.4);
        border-radius: 40px;
        display: flex;
        .left {
            flex: 1;
            display: grid;
            img {
                display: block;
            }
            .login-back-logo {
                width: 500px;
                height: 321px;
                margin: 0 auto;
            }
        }
        .right {
            width: 360px;
            //flex: 1;
            margin: 30px 30px 0 60px;
            .login-title {
                color: #0ab2c1;
                font-family: 'Source Han Sans CN';
                .welcome-title {
                    font-size: 20px;
                }
                .company-title {
                    font-size: 24px;
                    font-weight: 600;
                }
                .underline {
                    height: 4px;
                    background: #0ab2c1;
                    width: 103px;
                    border-radius: 1px;
                    margin: 15px 0 45px 0;
                }
            }
            .ant-form {
                .ant-input-affix-wrapper,
                .ant-input-group-addon,
                .ant-input-affix-wrapper-focused,
                .ant-input-affix-wrapper:focus {
                    border-right: none;
                    border-top: none;
                    border-left: none;
                    border-bottom: none;
                    box-shadow: none;
                    border-radius: 0;
                    background: rgba(0, 0, 0, 0);
                }
                .ant-form-item-with-help {
                    margin-bottom: 0;
                }
                .code-overdue {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.3);
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    cursor: pointer;
                }
                .verify-code-img {
                    width: 100px;
                    height: 30px;
                    position: relative;
                    z-index: 1;
                }
                .ant-btn-primary {
                    margin-top: 45px;
                    width: 100%;
                    background: linear-gradient(
                        -90deg,
                        #0ab2c1 0%,
                        #27cedd 100%
                    );
                    box-shadow: 0 6px 10px 0 rgba(10, 178, 193, 0.4);
                    border-radius: 10px;
                    border: none;
                }
                .ant-form-item-control-input-content {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    border-bottom: 1px solid #d9d9d9;
                }
                .ant-input-affix-wrapper-disabled .ant-input[disabled] {
                    color: #4d4d4d;
                }
                .ant-segmented-item-selected {
                    background: #0ab2c1;
                    color: #fff;
                }
                .org-item {
                    .ant-select {
                        width: 100%;
                        border: none;
                        display: flex;
                        align-items: center;
                        &::before {
                            content: '';
                            color: #0ab2c1;
                            background: url(${jigou}) no-repeat center center;
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                        }
                    }
                    .ant-select-selector,
                    .ant-select-open,
                    .ant-select-focused {
                        border: none;
                        box-shadow: none;
                    }
                }
                //.ant-radio-group-solid
                //    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                //    background: #0ab2c1;
                //    border-color: #0ab2c1;
                //}
            }
        }
        .remember-password {
            .ant-col-offset-8 {
                margin-left: 0;
            }
            .ant-form-item-control-input-content {
                border-bottom: none !important;
            }
        }
    }
    .copyright {
        padding-top: 20px;
        //line-height: 100px;
        color: #fff;
    }
    .no-captcha-layout {
        padding-top: 40px;
        //.ant-form-item:nth-child(3) {
        //    margin-bottom: 46px;
        //}
        .ant-btn-primary {
            margin-top: 30px !important;
        }
    }
    .login-btn .ant-btn-primary {
        margin-top: 0px !important;
    }
`;

export default LoginPage;
