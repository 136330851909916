import { http } from '../utils';
import { TReqLogin, TResLogin } from './../models/index';

const api = {
    // 获取图形验证码
    getVerificationCode(params: { requestId: string }) {
        return http.get<string>('/api/v1/login/captcha', { params });
    },
    // 登陆系统
    login(body: TReqLogin, params: { requestId: string }) {
        return http.post<TReqLogin, TResLogin>('/api/v1/login/login', body, {
            params,
        });
    },
    // 获取注册机构列表
    getRegisterOrg(params?: { account: string }) {
        return http.get('/api/v1/login/getLoginSaasIds', { params });
    },
};

export default api;
