import AES from 'crypto-js/aes';
import ECB from 'crypto-js/mode-ecb';
import encHex from 'crypto-js/enc-hex';
import Pkcs7 from 'crypto-js/pad-pkcs7';
import encUtf8 from 'crypto-js/enc-utf8';

import React, { Component } from 'react';
import { TAppConf } from '@gf-tech/types';
import { EventBus, menuDispose } from '@gf-tech/utils';
import { AppProps, navigateToUrl } from 'single-spa';
import {
    Form,
    Input,
    Button,
    FormInstance,
    Dropdown,
    Menu,
    message,
    Checkbox,
    Select,
    InputRef,
} from 'antd';

import api from './api';
import { TReqLogin } from './models';
import LoginPage from './styled.component';
import DownLoadClient from './component/DownLoadClient';
import { baseURL, http, store } from './utils';
import jigou from './assets/jigou.png';
import loginBackLogo from './assets/login-back-logo.png';
import loginLogo from './assets/login-logo.png';
import './App.css';
import {
    ApiOutlined,
    UserOutlined,
    SafetyCertificateOutlined,
    UnlockOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import PasswordDispose from './utils/passwordDispose';

type TOrg = {
    saasName: string;
    pinyin: string;
    saasId: number;
    shortCode: string;
};

type TAppState = {
    verifyCode: string;
    selectedKeys: string[];
    showCaptcha: boolean;
    loading: boolean;
    orgList: TOrg[];
    codeOverdue: boolean; // 二维码是否过期
    defOrgShortCode: string; // 默认选择机构的code，当为机构的选项的时候，如果当前defOrgShortCode有值，就莫选中当前defOrgShortCode对应的机构
    isRemember: boolean; // 是否记住密码
};

const Apis = [
    { label: '开发', value: 'dev' },
    { label: '测试', value: 'test' },
    { label: '演示', value: 'demo' },
];

let timer = null;
export default class Root extends Component<AppProps & TAppConf, TAppState> {
    constructor(props: AppProps & TAppConf) {
        super(props);

        this.state = {
            verifyCode: '',
            selectedKeys: [],
            showCaptcha: false,
            loading: false,
            orgList: [],
            codeOverdue: false,
            defOrgShortCode: '',
            isRemember: true,
        };

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ store, baseURL: appConf.apiHost + baseURL });
    }

    secretKey = '13a586227b2a9a3d';

    refForm = React.createRef<FormInstance<TReqLogin>>();
    refPwdIpt = React.createRef<InputRef>();

    async getVerificationCode() {
        const requestId = store.get('requestId');
        const code = await api.getVerificationCode({ requestId });
        const sysConf = store.get('sysConf');
        const { captchaModel } = sysConf;
        this.setState(
            {
                verifyCode: code,
                showCaptcha: captchaModel,
                codeOverdue: false,
            },
            () => {
                this.codeOverdueTime();
            }
        );
    }

    async codeOverdueTime() {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            this.setState({
                codeOverdue: true,
            });
        }, 1000 * 60);
    }

    async submit(data) {
        const { isRemember } = this.state;
        const decodePassword = PasswordDispose.encryptionPassword(
            data.password
        );
        if (isRemember) {
            store.set(
                'userAccount',
                JSON.stringify({
                    account: data.account,
                    password: decodePassword,
                })
            );
        } else {
            store.remove('userAccount');
        }
        data.account = this.loginAccountDispose(data.org, data.account);
        delete data.org;

        this.setState({
            loading: true,
        });
        const encrypted = AES.encrypt(
            data.password,
            encUtf8.parse(this.secretKey),
            {
                mode: ECB,
                padding: Pkcs7,
            }
        );
        const encryptedPwd = encHex.stringify(encrypted.ciphertext);

        try {
            const requestId = store.get('requestId');
            const res = await api.login(
                {
                    ...data,
                    loginMode: 'PASSWORD',
                    password: encryptedPwd,
                },
                { requestId }
            );
            res.userInfo.resList = menuDispose(
                res.userInfo.resList,
                res.userInfo.saasCode
            );
            store.set('token', res.token);
            store.set('currentAppIndex', 0);
            store.set('userInfo', res.userInfo);
            EventBus.dispatch('app:ws-connect', null);
            window.screenLocker.worker.postMessage(res.userInfo.screenLock);
            window.screenLocker.worker.postMessage('unlock');

            this.setState({
                loading: false,
            });
            const paths = {
                '028': '/trauma',
            };
            navigateToUrl(paths[res.userInfo.currentRes] || '/index/home');
        } catch (err) {
            this.setState({
                loading: false,
            });
            this.refForm.current.setFields([{ name: 'captcha', value: '' }]);
            this.getVerificationCode();
        }
    }

    componentDidMount() {
        store.remove('authCode');
        (async () => {
            document.querySelector('body').className = 'login-style';
            if (store.get('token')) {
                const urlParams = new URLSearchParams(window.location.search);
                const paramValue = urlParams.get('attrs');
                const uriRoute = urlParams.get('path');
                if (paramValue === '能源管理') {
                    navigateToUrl(uriRoute || '/xnyy-energy');
                } else if (paramValue?.includes('能源')) {
                    navigateToUrl(uriRoute || '/energy');
                } else {
                    navigateToUrl('/index/home');
                }
                return;
            }
            let userAccount = store.get('userAccount');
            if (userAccount) {
                userAccount = JSON.parse(userAccount);
                userAccount.password = PasswordDispose.decodePassword(
                    userAccount.password
                );
                const { account, password } = userAccount;
                this.refForm.current.setFieldsValue({ account, password });
            }
            this.setState({ selectedKeys: [store.get('env')] });
            this.getVerificationCode();
            this.getOrgList();
        })();
    }

    componentWillUnmount() {
        document.querySelector('body').className = '';
        if (timer) clearInterval(timer);
    }

    setEnv(env: string) {
        store.set('env', env);
        window.location.reload();
    }

    getOrgList(account?: string) {
        (async () => {
            const orgList: TOrg[] = await api.getRegisterOrg({ account });
            try {
                const sysConf = store.get('sysConf');
                const { defOrgShortCode } = sysConf;
                this.setState(
                    {
                        orgList,
                        defOrgShortCode,
                    },
                    () => {
                        this.onReloadOrgValue();
                    }
                );
            } catch (e) {
                this.setState({ orgList: [] });
            }
        })();
    }

    loginAccountDispose(orgName: string, account: string) {
        const { orgList } = this.state;
        const orgInfo = orgList.find(
            item => item.saasName === orgName || item.shortCode === orgName
        );
        if (!orgInfo) {
            message.error('请输入正确的机构注册编码！');
            throw new Error('机构注册编码不正确');
        }
        if (orgInfo.shortCode === '00000') return account;
        return account + '@' + orgInfo.saasId;
    }

    onRegisterOrgChange(e: any) {
        const { value } = e.target;
        const { orgList } = this.state;
        let currentOrg = orgList.find(item => item.shortCode === value);
        this.setOrgValue(currentOrg?.saasName || value);
    }

    onReloadOrgValue() {
        const { orgList } = this.state;
        const sysConf = store.get('sysConf');
        const { defOrgShortCode } = sysConf;
        if (orgList.length === 1) {
            const [orgInfo] = orgList;
            this.setOrgValue(orgInfo?.saasName);
            return;
        }
        if (defOrgShortCode) {
            const orgInfo = orgList.find(
                item => item.shortCode === defOrgShortCode
            );
            this.setOrgValue(orgInfo?.saasName);
        }
    }

    onOrgFilterOption(
        value: string,
        options: { value: string; label: string }
    ) {
        return options.value.includes(value) || options.label.includes(value);
    }

    setOrgValue(value: string) {
        this.refForm.current.setFieldsValue({
            org: value,
        });
    }

    onPwdFocus() {
        this.refPwdIpt.current.focus();
    }

    onRememberPasswordChange(e) {
        this.setState({
            isRemember: e.target.checked,
        });
    }

    renderApis() {
        return (
            <Menu selectedKeys={this.state.selectedKeys}>
                {Apis.map(item => (
                    <Menu.Item
                        key={item.value}
                        onClick={({ key }) => this.setEnv(key)}
                    >
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

    render() {
        const { showCaptcha, loading, codeOverdue, orgList } = this.state;
        const { shortMode } = store.get('sysConf');

        return (
            <LoginPage>
                <div className='login-box'>
                    <div className='left'>
                        <img
                            alt=''
                            src={this.props.appConf?.newLogo || loginLogo}
                            className='logo'
                        />
                        <img
                            alt=''
                            src={loginBackLogo}
                            className='login-back-logo'
                        />
                    </div>
                    <div className='right'>
                        <div className='login-title'>
                            <div className='welcome-title'>您好！欢迎来到</div>
                            <div className='company-title'>
                                {this.props.appConf.appName}
                            </div>
                            <div className='underline' />
                        </div>
                        <Form
                            size='large'
                            ref={this.refForm}
                            onFinish={data => this.submit(data)}
                            className={showCaptcha ? '' : 'no-captcha-layout'}
                        >
                            {shortMode ? (
                                <Form.Item
                                    name='org'
                                    className='org-item'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择注册机构!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder='请选择注册机构'
                                        showSearch
                                        filterOption={this.onOrgFilterOption.bind(
                                            this
                                        )}
                                        options={orgList.map(item => ({
                                            value: item.shortCode,
                                            label: item.saasName,
                                        }))}
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    name='org'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入注册机构代码!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='请输入注册机构代码'
                                        allowClear
                                        prefix={<img alt='' src={jigou} />}
                                        onChange={this.onRegisterOrgChange.bind(
                                            this
                                        )}
                                        addonAfter={
                                            <ReloadOutlined
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={this.onReloadOrgValue.bind(
                                                    this
                                                )}
                                            />
                                        }
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                name='account'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入登录账号!',
                                    },
                                ]}
                            >
                                <Input
                                    onPressEnter={this.onPwdFocus.bind(this)}
                                    prefix={<UserOutlined />}
                                    placeholder='请输入登录账号'
                                />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入登录密码!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    ref={this.refPwdIpt}
                                    prefix={<UnlockOutlined />}
                                    placeholder='请输入登录密码'
                                />
                            </Form.Item>
                            {/* TODO: 验证码失效时间为分钟 */}
                            {showCaptcha && (
                                <Form.Item
                                    style={{
                                        display: showCaptcha ? 'block' : 'none',
                                    }}
                                    name='captcha'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入验证码!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<SafetyCertificateOutlined />}
                                        autoComplete='off'
                                        placeholder='请输入验证码'
                                        className='verify-code-input'
                                        addonAfter={
                                            <div
                                                role='button'
                                                tabIndex={0}
                                                onClick={() =>
                                                    this.getVerificationCode()
                                                }
                                            >
                                                <img
                                                    alt=''
                                                    aria-hidden='true'
                                                    className='verify-code-img'
                                                    src={this.state.verifyCode}
                                                />
                                                {codeOverdue ? (
                                                    <span className='code-overdue'>
                                                        已过期
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        }
                                    />
                                </Form.Item>
                            )}
                            <Form.Item className='remember-password'>
                                <Checkbox
                                    checked={this.state.isRemember}
                                    onChange={this.onRememberPasswordChange.bind(
                                        this
                                    )}
                                >
                                    记住密码
                                </Checkbox>
                            </Form.Item>
                            <Form.Item className='login-btn'>
                                <Button
                                    loading={loading}
                                    type='primary'
                                    htmlType='submit'
                                    className='submit-btn'
                                >
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className='copyright'>
                    {/* Copyright © 2023 guanfangyiliao.com All Rights Reserved*/}
                    {(this.props.appConf as any).copyright ||
                        'Copyright © 2023 guanfangyiliao.com All Rights Reserved'}
                </div>
                <>
                    {this.props.appConf.env === 'dev' && (
                        <Dropdown overlay={this.renderApis()}>
                            <ApiOutlined
                                style={{
                                    top: '10px',
                                    left: '10px',
                                    color: '#fff',
                                    fontSize: '20px',
                                    position: 'absolute',
                                }}
                            />
                        </Dropdown>
                    )}
                </>
                <DownLoadClient />
            </LoginPage>
        );
    }
}
